import React from 'react';
import BlogArticle from '../BlogArticle/BlogArticle';
import { Link } from 'gatsby';

import * as styles from './BlogArticles.module.css';

interface IBlogArticles {
  posts: any[];
}

const BlogArticles: React.FC<IBlogArticles> = ({ posts }) => (
  <div className={styles.BlogArticles}>
    {posts.map(({ node }: any) => {
      const title = node.frontmatter.title || node.fields.slug;
      const image = node.frontmatter.image?.childImageSharp.gatsbyImageData;

      return (
        <Link
          to={node.fields.slug}
          className={styles.BlogArticles__article}
          key={node.fields.slug}
        >
          <BlogArticle
            title={title}
            html={node.frontmatter.description || node.excerpt}
            date={node.frontmatter.date}
            image={image}
          />
        </Link>
      );
    })}
  </div>
);

export default BlogArticles;
