import { graphql } from 'gatsby';
import React from 'react';
import BlogArticles from '../components/BlogArticles/BlogArticles';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export const BlogIndex: React.FC<any> = ({ data }) => {
  const posts = data.allMarkdownRemark.edges;
  const instagramPosts = data.allInstagramContent.edges;

  return (
    <Layout instagramPosts={instagramPosts}>
      <SEO title="All posts" />
      <BlogArticles posts={posts} />
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD.MM.YYYY")
            title
            description
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 1000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }

    allInstagramContent(limit: 8) {
      edges {
        node {
          id
          caption
          permalink # will be omitted if the Media contains copyrighted material, or has been flagged for a copyright violation
          media_url
          localImage {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                width: 500
                height: 500
              )
            }
          }
        }
      }
    }
  }
`;
