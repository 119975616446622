import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import * as styles from './BlogArticle.module.css';

type INativeDivElement = React.HTMLAttributes<HTMLDivElement>;
interface IBlogArticle extends INativeDivElement {
  title: string;
  date: string;
  html: string;
  image: IGatsbyImageData;
}

// @TODO: sava
// categories: food, places

const BlogArticle: React.FC<IBlogArticle> = ({
  title,
  date,
  html,
  image,
  ...props
}) => (
  <div className={styles.BlogArticle} {...props}>
    <GatsbyImage
      className={styles.BlogArticle__image}
      image={image}
      alt={title}
    />
    <div className={styles.BlogArticle__content}>
      <h3 className={styles.BlogArticle__header}>{title}</h3>
      <small className={styles.BlogArticle__date}>{date}</small>
    </div>
  </div>
);

export default BlogArticle;
